import { HIDE_FLASH_MESSAGE, SHOW_FLASH_MESSAGE } from "../actions/types";

export default function(state = { visible: false, message: "" }, action) {
  switch (action.type) {
    case SHOW_FLASH_MESSAGE:
      return { ...state, visible: true, message: action.payload.message };
    case HIDE_FLASH_MESSAGE:
      return { ...state, visible: false };
    default:
      return state;
  }
}
