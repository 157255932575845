import React, { Component } from "react";
import { connect } from "react-redux";

class Loading extends Component {
  render() {
    return (
      <div className={this.props.loading ? "spinner spinner-round" : ""}></div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: Object.keys(state.loading.loadingStack).length > 0,
    loadState: state.loading
  };
}
export default connect(mapStateToProps)(Loading);
